.AuthLayout {
  position: fixed;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

    .ant-image.logo{
        display: block;
      .ant-image-img {
          width: 150px;
          height: auto;
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: -60px;
      }
  }
  .input-auth {
    height: 40px;
    border-radius: 8px;
  }
}
