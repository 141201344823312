.purchase-order {

    // Ant Design Modified Class
    .ant-input {
        height: 40px;
        border-radius: 8px;
    }

    .ant-input-number {
        height: 40px;
        width: 100%;
        border-radius: 8px;
    }

    .ant-input-search {
        height: 40px;
        padding: 0 8px;
        border-radius: 8px;
    }

    .ant-input-affix-wrapper>input.ant-input {
        padding: 0;
        height: 38px;
        border: none;
        outline: none;
    }

    .ant-input-search-button {
        height: 40px;
    }

    .ant-picker {
        height: 40px;
        border-radius: 8px;
    }

    textarea.ant-input {
        height: 40px;
        min-height: 40px;
        border-radius: 8px;
    }

    .ant-btn,
    .ant-btn:active,
    .ant-btn:focus {
        border-radius: 5px;
    }

    .ant-form-item-label>label {
        position: relative;
        display: inline-flex;
        align-items: center;
        height: 32px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
    }

    .ant-form-item-label {
        font-weight: bold;
    }

    .ant-card {
        background: none;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .ant-card-head {
        border-radius: 10px 10px 0 0;
    }

    .ant-card-body {
        background: #fff;
        border-radius: 10px 10px 0 0;
    }

    // Custom Class
    .create-button {
        margin-right: 20px;
        background-color: #154D9F;
        color: white;
    }

    .edit-button {
        background-color: #F6C001;
        margin-right: 20px;
    }

    .content-title {
        margin-bottom: 16px;
        font-size: 28px;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: #154D9F;
    }

    .title-confirm {
        display: flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
    }

    .fa-history-icon {
        font-size: 30px !important;
    }

    .date-picker-input {
        width: 100%;
    }

    .upload-list-inline .ant-upload-list-item {
        float: left;
        width: 200px;
        margin-right: 8px;
    }

    .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
        float: right;
    }
    .upload-list-inline.ant-upload-disabled {
        display: none;
    }
}