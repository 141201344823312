.master-data {
    .selectInput {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        transition: all 0.3s;
    }

    .ant-input {
        height: 40px;
        border-radius: 8px;
    }

    .ant-input-number {
        height: 40px;
        width: 100%;
        border-radius: 8px;
    }

    .ant-picker {
        height: 40px;
        border-radius: 8px;
    }

    textarea.ant-input {
        height: 40px;
        min-height: 40px;
        border-radius: 8px;
    }

    .ant-btn,
    .ant-btn:active,
    .ant-btn:focus {
        border-radius: 5px;
    }

    .ant-form-item-label>label {
        position: relative;
        display: inline-flex;
        align-items: center;
        height: 32px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
    }

    .ant-form-item-label {
        font-weight: bold;
    }

    .ant-card {
        background: none;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .ant-card-head {
        border-radius: 10px 10px 0 0;
    }

    .ant-card-body {
        background: #fff;
        border-radius: 10px 10px 0 0;
    }

    .content-title {
        margin-bottom: 16px;
        font-size: 28px;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: #154D9F;
    }

    .editable-cell {
        position: relative;
    }

    .editable-cell-value-wrap {
        padding: 5px 12px;
        cursor: pointer;
    }

    .editable-row:hover .editable-cell-value-wrap {
        padding: 4px 11px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
}