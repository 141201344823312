.forbidden-page {
  position: fixed;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .ant-image.logo {
    display: block;
    .ant-image-img {
      display: block;
      width: 250px;
      height: auto;
      margin: auto;
    }
  }
}
