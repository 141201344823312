// @import '~antd/dist/antd.less';
body,
#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Layout-content {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  margin: 1.25em;
}

.fa-icon-button {
  margin-right: 8px;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  padding: 0;
  border-radius: 10px 10px 0 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  border-radius: 8px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 35px;
}

.ant-input[disabled] {
  color: #00000080;
}

.ant-input-number-disabled {
  color: #00000080;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: #00000080;
}

.ant-picker-input>input[disabled] {
  color: #00000080;
}

.ant-input {
  height: 40px;
  border-radius: 8px;
}

.ant-input-number {
  height: 40px;
  width: 100%;
  border-radius: 8px;
}

.ant-input-search {
  height: 40px;
  padding: 0 8px;
  border-radius: 8px;
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  height: 38px;
  border: none;
  outline: none;
}

.ant-input-search-button {
  height: 40px;
}

.ant-picker {
  height: 40px;
  border-radius: 8px;
}

textarea.ant-input {
  height: 40px;
  min-height: 40px;
  border-radius: 8px;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  border-radius: 5px;
}

.ant-form-item-label>label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.ant-form-item-label {
  font-weight: bold;
}

.ant-card {
  background: none;
  border-radius: 10px;
  margin-bottom: 20px;
}

.ant-card-head {
  border-radius: 10px 10px 0 0;
}

.ant-card-body {
  background: #fff;
  border-radius: 10px 10px 0 0;
}

.content-title {
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #154D9F;
}

.date-picker-input {
  width: 100%;
  height: 40px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}